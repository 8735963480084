<template>
  <div class="d-flex h-100 align-items-center justify-content-around">
    <div class="d-none d-lg-block">
      <h1>
        Tu propia flotilla,
        <br />sin inversión
      </h1>
    </div>
    <z-card>
      <img src="@/assets/img/vertical-logo.png" />
      <div v-show="emailSend">
        <strong>¡Ya casi, un paso más!</strong>
        <br />
        Te enviamos las instrucciones para recuperar tu contraseña a
        {{ emailSent }}
      </div>
      <div v-show="hasErrors">
        <strong>¡Tenemos un problema!</strong>
        <br />Tenemos problemas localizando tu usuario, por favor revisa que tu
        correo sea el correcto.
      </div>
      <div>
        <div class="text mb-3" :class="{ 'd-none': emailSend || hasErrors }">
          Para recuperar tu contraseña escribe tu correo
        </div>
        <div class="mx-2">
          <b-input
            id="email"
            v-model="email"
            :state="emailState"
            type="email"
            class="form-control"
            placeholder="Escribe tu correo electrónico"
          />
        </div>
      </div>
      <div class="my-4">
        <z-button
          :disabled="!validForm"
          :loading="isLoading"
          @click="recoverEmail"
          >Recuperar contraseña</z-button
        >
        <div class="mt-3">
          <router-link :to="{ name: 'login' }"
            >Ingresar con mis datos de usuario</router-link
          >
        </div>
      </div>
    </z-card>
  </div>
</template>

<script>
import { validateEmail } from "@/utils/strings";
import ZCard from "./ZCard";

export default {
  name: "Forgot",

  components: { ZCard },

  data() {
    return {
      email: "",
      emailSent: "",
      isLoading: false,
      emailSend: false,
      hasErrors: false
    };
  },

  computed: {
    emailState() {
      return this.email.length === 0 ? null : validateEmail(this.email);
    },

    validForm() {
      return this.emailState && !this.isLoading;
    }
  },

  methods: {
    recoverEmail: function() {
      this.isLoading = true;
      this.$store
        .dispatch("user/emailRecovery", this.email)
        .then(() => {
          this.emailSend = true;
          this.hasErrors = false;
          this.emailSent = this.email;
          this.email = "";
        })
        .catch(err => {
          this.$captureError(err);
          this.hasErrors = true;
          this.emailSend = false;
        })
        .finally(() => {
          this.isLoading = false;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
h1 {
  font-size: 50px;
}

.text {
  font-size: 18px;
}

img {
  width: 150px;
  height: 150px;
}

.form-control {
  width: 308px;
  font-size: 13px;
  padding: 0.575rem 0.75rem;
}
</style>
